.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}

.google-map {
    width: 100%;
    height: 100%;
}

.pin {
    display: flex;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-left: -2rem;
    margin-top: -4rem;
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    font-size: 1.3em;
}

/* @media screen and (min-width: 799px) {
    .pin {
        width: 15vw;
    }

    .pin-icon {
        font-size: 10vw;
    }
} */

.location-marker .map-icon {
    margin-top: -0.8rem;
}
.navigation-item-icon-container .map-icon {
    width: 1.4rem;
    height: 1.4rem;
}
.map-icon {
    padding: 0.2rem;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
}
.map-icon svg {
    width: 1rem;
    height: 1rem;
}

.map-location-label {
    width: 8rem;
    margin-left: -3.2rem;
    text-shadow: 1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white;
}
.map-location-label.satellite {
    width: 8rem;
    margin-left: -3.2rem;
    color: white;
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black;
}

.map-location-label.zoom-15 {
    font-size: 0.8rem;
}
.map-location-label.zoom-14 {
    font-size: 0.8rem;
}
.map-location-label.zoom-13 {
    font-size: 0.8rem;
}
.map-location-label.zoom-12 {
    font-size: 0.8rem;
}
.map-location-label.zoom-11 {
    font-size: 0.8rem;
}
.map-location-label.zoom-10 {
    font-size: 0.8rem;
}
.map-location-label.zoom-9 {
    font-size: 0.8rem;
}
.map-location-label.zoom-8 {
    font-size: 0.8rem;
}
.map-location-label.zoom-7 {
    font-size: 0.8rem;
}
.map-location-label.zoom-6 {
    font-size: 0.8rem;
}
.map-location-label.zoom-5 {
    font-size: 0.8rem;
}
.map-location-label.zoom-4 {
    font-size: 0.8rem;
}
.map-location-label.zoom-3 {
    font-size: 0.8rem;
}
.map-location-label.zoom-2 {
    font-size: 0.8rem;
}
.map-location-label.zoom-1 {
    font-size: 0.8rem;
}
